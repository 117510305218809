<template>
  <div class="declaration_pop">
    <h4>아래에서 신고할 내용을 선택해주세요.</h4>
    <div class="sel_type">
      <ul>
        <li>
          <label
            ><input
              type="radio"
              :name="`declaration_${item.seq}`"
              value="5"
              v-model="inputData"
            />과장 광고형 리뷰입니다.</label
          >
        </li>
        <li>
          <label
            ><input
              type="radio"
              :name="`declaration_${item.seq}`"
              value="6"
              v-model="inputData"
            />축소·왜곡·폄하를 위한 리뷰입니다.</label
          >
        </li>
        <li>
          <label
            ><input
              type="radio"
              :name="`declaration_${item.seq}`"
              value="7"
              v-model="inputData"
            />해당 상품/서비스와 관련 없는 내용입니다.</label
          >
        </li>
        <li>
          <label
            ><input
              type="radio"
              :name="`declaration_${item.seq}`"
              value="2"
              v-model="inputData"
            />불법물, 음란물이 포함되어 있습니다.</label
          >
        </li>
        <li>
          <label
            ><input
              type="radio"
              :name="`declaration_${item.seq}`"
              value="8"
              v-model="inputData"
            />타인의 저작권을 침해하는 리뷰입니다.</label
          >
        </li>
        <li>
          <label
            ><input
              type="radio"
              :name="`declaration_${item.seq}`"
              :value="`etc_${item.seq}`"
              :id="inputData"
              v-model="inputData"
            />기타(아래에 내용을 적어주세요.)</label
          >
        </li>
      </ul>
      <textarea v-model="etcContent" :readonly="isRedonly"></textarea>
    </div>
    <div class="btn">
      <router-link to="" @click.native="reportSet()" class="black"
        >신고하기</router-link
      ><router-link to="" @click.native="closeDeclaration()" class="gray"
        >취소</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      etcContent: null,
      isRedonly: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    closeDeclaration() {
      document.querySelector(`#declaration_${this.item.seq}`).style.display =
        "none";
      this.inputData = `etc_${this.item.seq}`;
      this.etcContent = null;
      document.querySelector(
        `input[value=etc_${this.item.seq}]`
      ).checked = true;
    },
    async reportSet() {
      let value = document.querySelector(
        `input[name=declaration_${this.item.seq}]:checked`
      ).value;
      if (value === `etc_${this.item.seq}`) {
        value = "etc";

        if (
          !this.etcContent ||
          this.etcContent.replace(/\s/g, "").length <= 0
        ) {
          this.etcContent = null;
          this.$toast.default(
            "기타 신고할 내용을 입력하시거나, 신고할 내용을 선택해주세요."
          );
          return false;
        }
      }
      await this.$store.dispatch("mypage/reportSet", {
        report_type: "review",
        report_seq: this.item.seq,
        report_reaseon: value,
        content: this.etcContent,
      });
      this.etcContent = null;
      this.$toast.default(this.$store.state.mypage.msg);
      this.closeDeclaration();
    },
  },
  computed: {
    inputData: {
      get() {
        return `etc_${this.item.seq}`;
      },
      set(value) {
        if (value !== `etc_${this.item.seq}`) {
          this.isRedonly = true;
          this.etcContent = null;
        } else {
          this.isRedonly = false;
        }
      },
    },
  },
};
</script>

<style></style>
