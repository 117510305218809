<template>
  <div class="reply_wrap">
    <!--로그인 전-->
    <div class="reply_input" v-if="!isCheckAuth">
      <input
        type="text"
        name=""
        class="before_login"
        readonly
        :placeholder="getPlaceholder"
      />
    </div>

    <!--로그인 후-->
    <div class="reply_input" v-else>
      <input
        type="text"
        name=""
        class="after_login"
        v-model="replyContent"
        placeholder="댓글을 입력해주세요."
        @keypress.enter="writeReply"
      /><router-link to="" @click.native="writeReply">댓글입력</router-link>
    </div>
    <!--로그인 후-->
    <div class="reply_sort" v-if="replyArrayList.length > 0">
      <select name="" v-model="sort" @change="setSort">
        <option value="DESC">최신순</option>
        <option value="ASC">오랜된 순</option>
      </select>
    </div>
    <div class="reply_list">
      <div class="reply_box" v-for="parent in replyArrayList" :key="parent.seq">
        <div class="reply" v-if="parseInt(parent.child_sort) <= 0">
          <div class="top">
            {{ parent.userid }}
            <router-link
              to=""
              v-if="parent.member_seq === myInfo.member_seq"
              style="margin-right: 30px"
              @click.native="modifyReply(item.seq, parent.seq)"
              >수정</router-link
            >
            <router-link
              to=""
              v-if="parent.member_seq === myInfo.member_seq"
              @click.native="deleteReply(item.seq, parent.seq)"
              >삭제</router-link
            >
            <router-link
              to=""
              v-if="parent.member_seq !== myInfo.member_seq && isCheckAuth"
              @click.native="setDeclaration(item.seq, parent.seq)"
              >신고</router-link
            >
          </div>
          <div
            class="bottom"
            :id="`reply_content_${item.seq}_${parent.seq}`"
            v-html="parent.content"
          >
            {{ parent.content }}
          </div>
          <div
            :id="`re_reply_wirte_${item.seq}_${parent.seq}`"
            style="display: none"
          >
            <div class="reply_input re_reply_re">
              <textarea
                :id="`modify_reply_${item.seq}_${parent.seq}`"
                rows="5"
                :value="textAreraBr(parent.content)"
              ></textarea>
            </div>
            <div class="reply_input re_reply_re">
              <router-link
                to=""
                @click.native="cacelModify(item.seq, parent.seq)"
                >수정 취소</router-link
              >
              <router-link
                to=""
                @click.native="
                  modifyReplyComplete(item.seq, parent.seq, 'parent')
                "
                >수정 완료</router-link
              >
            </div>
          </div>
        </div>
        <div class="re_reply_list" v-if="parseInt(parent.child_sort) <= 0">
          <div class="re_reply_bt" @click="openReply(item.seq, parent.seq)">
            답글 {{ parseInt(parent.child_cnt) }}개
            <router-link
              to=""
              :id="`replyArrow_${item.seq}_${parent.seq}`"
              class="replyArrow"
              >∨</router-link
            >
          </div>
          <div
            class="re_reply_box"
            :id="`re_reply_box_${item.seq}_${parent.seq}`"
            style="display: none"
          >
            <template v-for="child in replyArrayList">
              <div
                class="re_reply"
                :key="child.seq"
                v-if="
                  parseInt(parent.seq) === parseInt(child.parent_seq) &&
                  parseInt(child.child_sort) > 0
                "
              >
                <div class="top">
                  {{ child.userid }}
                  <router-link
                    to=""
                    v-if="child.member_seq === myInfo.member_seq"
                    style="margin-right: 30px"
                    @click.native="modifyReply(item.seq, child.seq)"
                    >수정</router-link
                  >
                  <router-link
                    to=""
                    v-if="child.member_seq === myInfo.member_seq"
                    @click.native="
                      deleteReReply(item.seq, child.seq, parent.seq)
                    "
                    >삭제</router-link
                  >
                  <router-link
                    to=""
                    v-if="child.member_seq !== myInfo.member_seq && isCheckAuth"
                    @click.native="setDeclaration(item.seq, child.seq)"
                    >신고</router-link
                  >
                </div>
                <div
                  class="bottom"
                  :id="`reply_content_${item.seq}_${child.seq}`"
                  v-html="child.content"
                >
                  {{ child.content }}
                </div>
                <div
                  :id="`re_reply_wirte_${item.seq}_${child.seq}`"
                  style="display: none"
                >
                  <div class="reply_input re_reply_re">
                    <textarea
                      :id="`modify_reply_${item.seq}_${child.seq}`"
                      rows="5"
                      :value="textAreraBr(child.content)"
                    ></textarea>
                  </div>
                  <div class="reply_input re_reply_re">
                    <router-link
                      to=""
                      @click.native="cacelModify(item.seq, child.seq)"
                      >수정 취소</router-link
                    >
                    <router-link
                      to=""
                      @click.native="
                        modifyReplyComplete(item.seq, child.seq, 'child')
                      "
                      >수정 완료</router-link
                    >
                  </div>
                </div>
              </div>
            </template>
            <div class="reply_input re" v-if="isCheckAuth">
              <input
                type="text"
                name=""
                placeholder="답글을 입력해주세요."
                class="after_login"
                v-model="reReplyContent"
                @keypress.enter="writeReReply(parent.seq)"
              /><router-link
                to=""
                class="router-link-exact-active router-link-active"
                @click.native="writeReReply(parent.seq)"
                >답글입력</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reply_more" v-if="replyTotalPage > page">
      <router-link to="" @click.native="moreReplyList"
        >{{ replyLimit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      replyContent: null,
      reReplyContent: null,
      sort: "DESC",
      authCheckSwitch: false,
      goods: 1,
      news: 3,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    replyListArray: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    textAreraBr(str) {
      return str.replace(/<br ?\/?>/g, "\r");
    },
    moreReplyList() {
      this.$emit("getReplyList");
    },
    async writeReply() {
      if (this.replyContent) {
        if (this.replyContent.replace(/\s/g, "").length <= 0) {
          this.$toast.default("댓글을 입력해주세요.");
          this.replyContent = null;
          return false;
        }
      } else {
        this.$toast.default("댓글을 입력해주세요.");
        this.replyContent = null;
        return false;
      }
      await this.$store.dispatch("review/writeReply", {
        review_seq: this.item.seq,
        content: this.replyContent,
        nickname: this.$store.state.mypage.myInfo.nickname,
        goods_seq: this.item.goods_seq,
        order_seq: this.item.order_seq,
        main_menu_seq: this.item.main_menu_seq,
      });

      this.replyContent = null; // 댓글 input 초기화
      this.$toast.default(this.msg);
    },
    async writeReReply(parentSeq) {
      if (this.reReplyContent) {
        if (this.reReplyContent.replace(/\s/g, "").length <= 0) {
          this.$toast.default("답글을 입력해주세요.");
          this.replyContent = null;
          return false;
        }
      } else {
        this.$toast.default("답글을 입력해주세요.");
        this.replyContent = null;
        return false;
      }
      await this.$store.dispatch("review/writeReply", {
        review_seq: this.item.seq,
        content: this.reReplyContent,
        nickname: this.$store.state.mypage.myInfo.nickname,
        goods_seq: this.item.goods_seq,
        order_seq: this.item.order_seq,
        parent_seq: parentSeq,
      });

      this.reReplyContent = null; // 댓글 input 초기화
      this.$toast.default(this.msg);
      if (this.result) {
        await this.$store.dispatch("review/getReReplyList", {
          seq: this.item.seq,
          parent_seq: parentSeq,
          type: "plus",
        });
      }
    },
    setSort() {
      this.$emit("setSort", this.sort);
    },
    openReply(seq, replySeq) {
      const reReply = document.querySelector(
        `#re_reply_box_${seq}_${replySeq}`
      );
      const reRePlyList = document.querySelectorAll(".re_reply_box");
      const replyArrow = document.querySelector(
        `#replyArrow_${seq}_${replySeq}`
      );
      [...reRePlyList].map((list) => {
        if (list !== reReply) {
          list.style.display = "none";
        }
      });

      [...document.querySelectorAll(".replyArrow")].map(
        (re) => (re.textContent = "∨")
      );
      if (reReply.style.display === "none") {
        reReply.style.display = "block";
        replyArrow.textContent = "∧";
      } else {
        reReply.style.display = "none";
        replyArrow.textContent = "∨";
      }
    },
    modifyReply(seq, replySeq) {
      const text = document.querySelector(`#re_reply_wirte_${seq}_${replySeq}`);
      const textarea = text
        .querySelectorAll(".reply_input.re_reply_re")[0]
        .querySelector("textarea");

      const content = document.querySelector(
        `#reply_content_${seq}_${replySeq}`
      );
      const orgContent = this.replyArrayList.filter(
        (reply) => parseInt(reply.seq) === parseInt(replySeq)
      );

      if (text.style.display === "none") {
        text.style.display = "block";
        content.style.display = "none";
      } else {
        content.style.display = "block";
        text.style.display = "none";
        textarea.value = this.textAreraBr(orgContent[0].content.trim());
      }
    },
    cacelModify(seq, replySeq) {
      const text = document.querySelector(`#re_reply_wirte_${seq}_${replySeq}`);
      const textarea = text
        .querySelectorAll(".reply_input.re_reply_re")[0]
        .querySelector("textarea");
      const content = document.querySelector(
        `#reply_content_${seq}_${replySeq}`
      );
      const orgContent = this.replyArrayList.filter(
        (reply) => parseInt(reply.seq) === parseInt(replySeq)
      );

      content.style.display = "block";
      text.style.display = "none";
      textarea.value = this.textAreraBr(orgContent[0].content.trim());
    },
    async modifyReplyComplete(seq, replySeq, type) {
      const content = document
        .querySelector(`#re_reply_wirte_${seq}_${replySeq}`)
        .querySelectorAll(".reply_input.re_reply_re")[0]
        .querySelector("textarea").value;
      const text = document.querySelector(`#re_reply_wirte_${seq}_${replySeq}`);
      const orgContent = document.querySelector(
        `#reply_content_${seq}_${replySeq}`
      );
      let info = "댓글";
      if (type === "child") {
        info = "답글";
      }

      if (content.replace(/\s/g, "").length <= 0) {
        this.$toast.default(`${info}을 입력해주세요.`);
        return false;
      }
      this.$confirm(`${info}을 수정하시겠습니까?`)
        .then(async () => {
          await this.$store.dispatch("mypage/updateReply", {
            content,
            seq: replySeq,
          });
          this.$toast.default(this.myMsg.replace("댓글", info));
          orgContent.style.display = "block";
          text.style.display = "none";
          if (this.myResult) {
            this.$store.dispatch("review/updateReply", {
              seq: replySeq,
              content: content.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
            });
          }
        })
        .catch(() => {
          return false;
        });
    },
    async deleteReply(seq, replySeq) {
      this.$confirm("댓글을 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/deleteRely", { seq: replySeq });
          this.$toast.default(this.myMsg);
          if (this.myResult) {
            this.$store.dispatch("review/updateReplyCnt", {
              type: "minus",
              seq,
              reply_seq: replySeq,
            });
          }
        })
        .catch(() => {
          return false;
        });
    },
    async deleteReReply(seq, replySeq, parentSeq) {
      this.$confirm("답글을 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/deleteRely", { seq: replySeq });
          this.$toast.default(this.myMsg);
          if (this.myResult) {
            await this.$store.dispatch("review/getReReplyList", {
              seq: this.item.seq,
              parent_seq: parentSeq,
              type: "minus",
              child_seq: replySeq,
            });
          }
        })
        .catch(() => {
          return false;
        });
    },
    async setDeclaration(seq, replySeq) {
      console.log(seq, replySeq);
      const content = document.querySelector(
        `#reply_content_${seq}_${replySeq}`
      );
      const orgContent = `(${replySeq}) ${content.textContent}`;
      await this.$store.dispatch("review/setReplyDeclaration", {
        report_type: "reply",
        report_seq: seq,
        content: orgContent,
        reply_seq: replySeq,
      });
      this.$toast.default(this.msg);
    },
  },
  computed: {
    ...mapState("review", [
      "msg",
      "result",
      "replyArrayList",
      "replyTotalPage",
      "replyLimit",
    ]),
    ...mapState("mypage", {
      myInfo: "myInfo",
      myMsg: "msg",
      myResult: "result",
    }),
    isCheckAuth: {
      get() {
        return this.authCheckSwitch;
      },
      set(value) {
        this.authCheckSwitch = value;
      },
    },

    getPlaceholder() {
      let msg = "";
      switch (parseInt(this.item.main_menu_seq)) {
        case this.goods:
          msg =
            "로그인 후, 이 리뷰를 통해 상품을 구매 하신 분들만 작성 가능합니다.";
          break;
        case this.news:
          // msg = "로그인 후, 이 리뷰를 원 기사를 읽은 분들만 작성 가능합니다.";
          msg = "로그인 후 작성 가능합니다.";
          break;
        default:
          msg = "";
          break;
      }
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.reply_input {
  &.re {
    margin-top: 10px;
  }
  &.re_reply_re {
    a {
      margin: 10px 0 0 0 !important;
    }
    a:nth-child(1) {
      background: white !important;
      color: #a7a7a7 !important;
      border: 1px solid #d3d3d3 !important;
    }
    a:nth-child(2) {
      float: right;
    }
    textarea {
      margin-top: 10px;
      width: 100%;
      border: 1px solid #d7d7d7;
      background: white;
      box-sizing: border-box;
    }
  }
}
</style>
